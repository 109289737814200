@use '~@sbt-web/ui/common/css/variables.scss';

@media (max-width: variables.$tablet_max_width) {
  .layout > main {
    margin-top: var(--sbt-spacing-xl);
  }
}

@media (min-width: variables.$desktop_min_width) {
  .layout {
    max-width: variables.$desktop_min_width;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    background-color: var(--sbt-background-default);
  }
  .layout > header,
  .layout > footer {
    margin: 0 auto var(--sbt-spacing-2xl);
  }
}

